<script>
export default {
  props: {
    values: {
      type: Object
    }
  },
  data () {
    return {
      snackbar: true,
      timeout: this.values.timeout
    }
  },
  methods: {
    close () {
      this.snackbar = true
      this.$store.dispatch('attemptSetFeedbackMsg', null)
    }
  },
  created () {
    if (this.values.timeout === -1) return
    setTimeout(() => {
      this.close()
    }, (this.values.timeout || 5000))
  }
}
</script>
<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :timeout="values.timeout ? values.timeout : 5000"
      :color="values.type === 'error' ? 'red' : values.type === 'success' ? 'green' : ''"
    >
      {{ values.title }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="close"
        >
          <v-icon color="white">mdi-window-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>

</style>
